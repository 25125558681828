import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MainLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PhotoGallery = makeShortcode("PhotoGallery");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Mobili per agriturismo`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`La novità dei nostri laboratori è una linea di mobili rustici per agriturismo, taverne ed esterni, caratterizzata dalla tradizionale cura per i dettagli propria della produzione artigianale. La peculiarità del legno di cedro trova nei nostri mobili la sua massima espressione, permettendoci di proporvi tavoli lunghi fino a 4,40 metri che daranno originalità al vostro giardino e alla vostra casa.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Per vedere l'immagine ingrandita, fare clic sull'anteprima`}</p>
      </li>
    </ul>
    <PhotoGallery mdxType="PhotoGallery">{[['/images/agriturismo/tavolo-1_b.jpg', 'Tavolone 4,00 m., tinta naturale, doppia panca'], ['/images/agriturismo/tavolo-2_b.jpg', 'Tavolone 4,40 m., tinta noce, doppia panca'], ['/images/agriturismo/tavolo-3_b.jpg', 'Tavolone 4,00, tinta chiara, doppia panca'], ['/images/agriturismo/tavolo-4_b.jpg', 'Tavolone 3,50 m., non tinto, con panca e sedie'], ['/images/agriturismo/tavolo-5_b.jpg', 'Tavolo 2,20 m., stile moderno, tinta rossa, gambe in acciaio'], ['/images/agriturismo/tavolo-6_b.jpg', 'Tavolo stile artistico, 1,80 m., tavola unica di noce']]}</PhotoGallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      